import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import User from 'models/User'
import { useAlert } from 'hooks/useAlert'

const ModalCopyCredentials = ({ open, user, onCancel }) => {
  const { setAlert } = useAlert()
  const [isCopied, setIsCopied] = useState(false)
  const [ownerUser, setOwnerUser] = useState(new User())

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(
      `Nombre: ${ownerUser?.name} ${ownerUser?.last_name}\nTeléfono: ${ownerUser?.phone}\nCelular: ${ownerUser?.cellphone}\nEmail: ${ownerUser?.email}\nContraseña: ${ownerUser?.password}`
    )
    setIsCopied(true)
    setAlert(true, 'info', 'Usuario copiado en portapapeles')
  }

  useEffect(() => {
    if (open) {
      setOwnerUser(user)
      setIsCopied(false)
    }
  }, [open])

  return (
    <Dialog open={open} keepMounted>
      <DialogContent>
        <Box>
          <Alert severity="success">
            <h3>Usuario propietario creado con éxito</h3>
          </Alert>
          <Box sx={{ marginTop: '.5rem' }}>
            <b>Nombre:</b> {ownerUser?.name} {ownerUser?.last_name} {ownerUser?.role?.name}
          </Box>
          <Box>
            <b>Email:</b> {ownerUser?.email}
          </Box>
          <Box>
            <b>Teléfono:</b> {ownerUser?.phone}
          </Box>
          <Box>
            <b>Celular:</b> {ownerUser?.cellphone}
          </Box>
        </Box>
        <Box sx={{ marginTop: '1rem' }}>¿Deseas copiar la información del nuevo usuario?</Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{ width: '10rem' }} variant="outlined" onClick={() => onCancel()}>
          Cerrar
        </Button>
        <Button
          sx={{ width: '10rem' }}
          startIcon={<ContentCopyIcon />}
          disabled={isCopied}
          variant="contained"
          onClick={handleCopyToClipboard}>
          {isCopied ? 'Copiado' : 'Copiar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalCopyCredentials.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  onCancel: PropTypes.func
}

export default ModalCopyCredentials
