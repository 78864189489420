import { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton } from '@mui/material'
// import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import PlanService from 'api/services/plan.service'
import SkeletonLoading from 'components/SkeletonLoading/SkeletonLoading'
import { useAlert } from 'hooks/useAlert'
import SideModalPlan from './components/SideModalPlan/SideModalPlan'
import Styles from './_Plans.module.scss'

const Plans = () => {
  const { setAlert } = useAlert()
  const [showSideModalPlan, setShowSideModalPlan] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState()
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(false)

  const getPlans = async () => {
    try {
      const resp = await PlanService.findAll()
      setPlans(resp)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCreatePlan = () => {
    setShowSideModalPlan(true)
  }

  const handleEditPlan = plan => {
    setSelectedPlan(plan)
    setShowSideModalPlan(true)
  }

  const handleCloseSideModalPlan = () => {
    setSelectedPlan()
    setShowSideModalPlan(false)
  }

  const handleSavePlan = result => {
    handleCloseSideModalPlan()
    if (result === 'success') {
      setAlert(true, 'success', 'Plan actualizado con éxito')
      getPlans()
    } else {
      setAlert(true, 'error', 'Ocurrió un error, intenta de nuevo más tarde')
    }
  }

  useEffect(() => {
    setLoading(true)
    getPlans()
  }, [])

  return (
    <>
      <h1>Planes</h1>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" color="primary" onClick={handleCreatePlan}>
          Nuevo Plan
        </Button>
      </Box>
      <Box sx={{ marginTop: '1rem' }}>
        {loading ? (
          <SkeletonLoading type="cards" />
        ) : (
          <Grid container spacing={3}>
            {plans.map((plan, index) => {
              return (
                <Grid key={index} item xs={12} lg={4} md={4}>
                  <Box className={Styles.plan__card}>
                    <Box className={Styles.plan__card_header}>
                      <IconButton onClick={() => handleEditPlan(plan)}>
                        <EditOutlinedIcon />
                      </IconButton>
                      {/* <IconButton>
                          <DeleteOutlinedIcon />
                        </IconButton> */}
                    </Box>
                    <Box className={Styles.plan__card_title}>{plan.name}</Box>
                    <Box className={Styles.plan__card_description}>{plan.description}</Box>
                    <Box className={Styles.plan__card_details}>
                      <Box>
                        <b>Slug:</b> {plan.slug}
                      </Box>
                      <Box>
                        <b>Stripe plan:</b> {plan.stripe_plan}
                      </Box>
                      <Box>
                        <b>Precio:</b> ${plan.price} {plan.currency}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        )}
      </Box>
      <SideModalPlan
        open={showSideModalPlan}
        selectedPlan={selectedPlan}
        onCancel={handleCloseSideModalPlan}
        onConfirm={handleSavePlan}
      />
    </>
  )
}

export default Plans
