import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DataTable from 'components/DataTable/DataTable'
import { useEffect, useState } from 'react'

const ModalManageUsers = ({ open, users, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const [usersList, setUsersList] = useState([])
  const [ownerUser, setOwnerUser] = useState()

  const headers = [
    {
      header: 'Email',
      key: 'email',
      cellType: 'text'
    },
    {
      header: 'Nombre',
      key: 'name',
      cellType: 'text'
    },
    {
      header: 'Apellidos',
      key: 'last_name',
      cellType: 'text'
    },
    {
      header: 'Teléfono fijo',
      key: 'phone',
      cellType: 'text'
    },
    {
      header: 'Teléfono celular',
      key: 'cellphone',
      cellType: 'text'
    },
    {
      header: 'Rol Interno',
      key: 'role',
      cellType: 'text'
    }
    // {
    //   header: 'Acciones',
    //   key: 'actions',
    //   cellType: 'action_buttons'
    //   action: handleActions
    // }
  ]

  const orderData = data => {
    setOwnerUser(data.find(element => element.is_owner))
    const orderedData = data
      .filter(element => !element.is_owner)
      .map(element => {
        const row = headers.map(header => {
          let value = element[header.key]
          if (header.key === 'role') {
            value = element[header.key].name
          }
          const obj = {
            cellType: header.cellType,
            key: header.key,
            value: value,
            action: (...values) => header.action(...values, element)
          }

          return obj
        })
        return row
      })
    return orderedData
  }

  useEffect(() => {
    if (users) {
      setUsersList(orderData(users))
    }
  }, [users])

  return (
    <Dialog open={open} keepMounted maxWidth="800px">
      <DialogTitle>
        <Box>Administrar usuarios</Box>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box sx={{ marginBottom: 2 }}>Usuario propietario:</Box>
          <h3>
            {ownerUser?.name} {ownerUser?.last_name} ({ownerUser?.role.name})
          </h3>
          <small>{ownerUser?.email}</small> <br />
          <small>{ownerUser?.cellphone}</small>
        </Box>
        <Box sx={{ margin: '2rem 0' }}>
          <DataTable headers={headers} data={usersList} maxHeight="20rem" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{ width: '10rem' }} disabled={loading} variant="outlined" onClick={() => onCancel()}>
          Cerrar
        </Button>
        {/* <Button sx={{ width: '10rem' }} disabled={loading} variant="contained" onClick={handleSubmit}>
          {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : 'Guardar Riesgo'}
        </Button> */}
      </DialogActions>
    </Dialog>
  )
}

export default ModalManageUsers
