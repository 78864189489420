import * as Yup from 'yup'

export const planFormSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  description: Yup.string().required('El nombre es requerido'),
  currency: Yup.string().required('El tipo de moneda es requerido'),
  price: Yup.number().required('El precio es requerido'),
  slug: Yup.string().required('La clave slug es requerida'),
  stripe_plan: Yup.string().required('El nombre del plan de Stripe es requerido')
})
