import { createTheme } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: 'Urbanist'
  },
  palette: {
    primary: {
      main: '#092336'
    },
    neutral: {
      main: '#eceff1'
    }
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            height: 'auto'
          }
        }
      ],
      styleOverrides: {
        root: {
          padding: '4px 20px',
          borderRadius: '20px',
          textTransform: 'none',
          height: '40px',
          whiteSpace: 'nowrap'
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 10
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: 'red'
        }
        // root: {
        //   '&. MuiInputLabel-asterisk': {
        //     color: 'red'
        //   },
        //   '&. MuiFormLabel-asterisk': {
        //     color: 'red'
        //   }
        // }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          marginBottom: '1.25rem',
          '&.MuiTextField-root': {
            marginBottom: '0',
            width: 'auto'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 11px 0px rgba(86, 86, 86, 0.31)',
          webkitBoxShadow: '0px 3px 11px 0px rgba(86, 86, 86, 0.31)',
          mozBoxShadow: '0px 3px 11px 0px rgba(86, 86, 86, 0.31)',
          justifyContent: 'space-between',
          display: 'flex'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '20px 24px !important'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          boxShadow: '0px -3px 11px 0px rgba(86, 86, 86, 0.31)',
          webkitBoxShadow: '0px 3px 11px 0px rgba(86, 86, 86, 0.31)',
          mozBoxShadow: '0px 3px 11px 0px rgba(86, 86, 86, 0.31)',
          padding: '1rem !important',
          justifyContent: 'center !important'
        }
      }
    }
  }
})

export default theme
