import dayjs from 'dayjs'

export const useDate = () => {
  const formatDate = date => {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }

  const formatDateTime = date => {
    return date ? dayjs(date).format('DD MMM YYYY - HH:MM') : ''
  }

  return { formatDate, formatDateTime }
}
