import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { Box, Button, CircularProgress, FormControl, TextField } from '@mui/material'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import AuthService from 'api/services/auth.service'
import AlertPopup from 'components/AlertPopup/AlertPopup'
import { loginFormSchema } from 'utils/schemas/loginFormSchema'
import Styles from './_Login.module.scss'

const Login = () => {
  const navigate = useNavigate()

  const [alert, setAlert] = useState({ open: false, severity: '', message: '' })
  const [loading, setLoading] = useState(false)
  const loginForm = { email: '', password: '' }

  const handleLogin = async form => {
    try {
      setLoading(true)
      const resp = await AuthService.login(form.email, form.password)
      localStorage.setItem('token', resp.token)
      localStorage.setItem('user', JSON.stringify(resp.user))
      setAlert({ open: true, severity: 'success', message: 'Loggeado con éxito. Cargando...' })
      setTimeout(() => {
        navigate('/')
      }, 1000)
    } catch (error) {
      console.log(error)
      setLoading(false)
      const message = error.response?.data?.message.includes('Credenciales Incorrectas')
        ? 'El email o la contraseña son incorretos'
        : 'Algo salió mal, intenta de nuevo más tarde'
      setAlert({ severity: 'error', open: true, message: message })
    }
  }

  const { errors, touched, values, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: loginForm,
    enableReinitialize: true,
    validationSchema: loginFormSchema,
    onSubmit: vals => {
      handleLogin(vals)
    }
  })

  useEffect(() => {
    setAlert({ open: false, severity: '', message: '' })
    setLoading(false)
  }, [])

  return (
    <>
      <Box className={Styles.layout}>
        <Box className={Styles.login__card}>
          <Box className={Styles.login__card__img}>
            <img src="/img/Logo_SideMenu.png" alt="" />
          </Box>
          <Box className={Styles.login__card__body}>
            <Box sx={{ textAlign: 'center', marginBottom: '2.5rem' }}>
              <h2 style={{ marginBottom: '1rem' }}>Inicia sesión</h2>
              <span style={{ color: '#777' }}>PMOX Management</span>
            </Box>
            <FormControl className={Styles.input}>
              <TextField
                name="email"
                label="Email"
                placeholder="Ingresa tu email"
                size="small"
                variant="outlined"
                value={values.email}
                error={touched?.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  startAdornment: <PersonOutlineIcon sx={{ marginRight: '.5rem' }} />
                }}
              />
            </FormControl>
            <FormControl className={Styles.input}>
              <TextField
                name="password"
                label="Contraseña"
                placeholder="Ingresa tu contraseña"
                size="small"
                variant="outlined"
                type="password"
                value={values.password}
                error={touched?.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                InputProps={{
                  startAdornment: <LockOutlinedIcon sx={{ marginRight: '.5rem' }} />
                }}
              />
            </FormControl>
          </Box>
          <Box className={Styles.login__card__footer}>
            <Button
              disabled={loading}
              sx={{ marginBottom: '2rem', width: '10rem' }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}>
              {loading ? <CircularProgress size="1.5rem" sx={{ color: '#C0C0C0' }} /> : 'Iniciar sesión'}
            </Button>
          </Box>
        </Box>
      </Box>
      <AlertPopup
        open={alert.open}
        severity={alert.severity}
        message={alert.message}
        handleClose={() => setAlert(prevState => ({ ...prevState, open: false }))}
      />
    </>
  )
}

export default Login
