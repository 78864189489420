import React from 'react'
import { useRoutes } from 'react-router-dom'
import routes from './router'

const App = () => {
  const routing = useRoutes(routes)
  return <React.Suspense fallback={<div>Cargando...</div>}>{routing}</React.Suspense>
}

export default App
