import { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SyncLockIcon from '@mui/icons-material/SyncLock'
import Visibility from '@mui/icons-material/Visibility'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import Paper from '@mui/material/Paper'
import { Box, Button, Chip, IconButton, TablePagination } from '@mui/material'
import { useDate } from 'hooks/useDate'
import SkeletonLoading from 'components/SkeletonLoading/SkeletonLoading'
import Styles from './_DataTable.module.scss'

const DrawCell = ({ item, index, disableEditAction }) => {
  const navigate = useNavigate()
  const { formatDate, formatDateTime } = useDate()

  const cellTypes = {
    action_buttons: (item, index) => {
      return (
        <TableCell size="small" key={index} component="th" scope="row">
          <IconButton
            onClick={() => item.action('edit')}
            disabled={item?.props?.disableEdit || disableEditAction || false}>
            <EditOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => item.action('delete')}>
            <DeleteOutlinedIcon />
          </IconButton>
        </TableCell>
      )
    },
    action_edit_button: (item, index) => {
      return (
        <TableCell size="small" key={index} component="th" scope="row">
          <IconButton
            onClick={() => item.action('edit')}
            disabled={item?.props?.disableEdit || disableEditAction || false}>
            <EditOutlinedIcon />
          </IconButton>
        </TableCell>
      )
    },
    action_buttons_reset_password: (item, index) => {
      return (
        <TableCell size="small" key={index} component="th" scope="row">
          <IconButton onClick={() => item.action('reset_password')}>
            <VpnKeyOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={() => item.action('edit')}
            disabled={item?.props?.disableEdit || disableEditAction || false}>
            <EditOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => item.action('delete')}>
            <DeleteOutlinedIcon />
          </IconButton>
        </TableCell>
      )
    },
    action_buttons_observer: (item, index) => {
      return (
        <TableCell size="small" key={index} component="th" scope="row">
          <IconButton onClick={() => item.action('view')}>
            <Visibility />
          </IconButton>
        </TableCell>
      )
    },
    reset_password_button: (item, index) => {
      return (
        <TableCell size="small" key={index} component="th" scope="row">
          <IconButton onClick={() => item.action()}>
            <SyncLockIcon />
          </IconButton>
        </TableCell>
      )
    },
    date: (item, index) => {
      return (
        <TableCell size="small" key={index} component="th" scope="row" sx={{ whiteSpace: 'nowrap' }}>
          {formatDate(item.value)}
        </TableCell>
      )
    },
    date_time: (item, index) => {
      return (
        <TableCell size="small" key={index} component="th" scope="row" sx={{ whiteSpace: 'nowrap' }}>
          {formatDateTime(item.value)}
        </TableCell>
      )
    },
    text: (item, index) => {
      return (
        <TableCell size="small" key={index} component="th" scope="row">
          {item.value}
        </TableCell>
      )
    },
    text_with_icon_button: (item, index) => {
      return (
        <TableCell size="small" key={index} component="th" scope="row">
          {item.value}
          <IconButton sx={{ marginLeft: '.5rem' }} onClick={() => item.action()}>
            <ManageAccountsIcon />
          </IconButton>
        </TableCell>
      )
    },
    chip: (item, index) => {
      return (
        <TableCell size="small" key={index} component="th" scope="row">
          <Chip label={item.value.label} sx={{ backgroundColor: item.value.color }} className={Styles.chip} />
        </TableCell>
      )
    },
    link: (item, index) => {
      return (
        <TableCell size="small" key={index} component="th" scope="row">
          <Button endIcon={<OpenInNewIcon />} onClick={() => navigate(item.value.url)}>
            {item.value.label}
          </Button>
        </TableCell>
      )
    }
  }

  const getCell = cellTypes[item.cellType]
  return getCell(item, index)
}

const DataTable = ({
  headers = [],
  data = [],
  isPaginated,
  loading,
  maxHeight = 'calc(100vh - 250px)',
  disableEditAction,
  rowsPerPage,
  page,
  total,
  onPageChange,
  onRowsPerPageChange,
  sortHeaderHandler
}) => {
  const [ascending, setAscending] = useState(false)
  const [selectedHeader, setSelectedHeader] = useState('')
  const sortTableBy = header => {
    setAscending(header !== selectedHeader ? true : !ascending)
    setSelectedHeader(header)
    sortHeaderHandler && sortHeaderHandler(header)
  }
  return (
    <Paper>
      <TableContainer style={{ maxHeight: maxHeight }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => {
                return (
                  <TableCell
                    sx={{ padding: '1rem .5rem' }}
                    className={header.ordering && Styles.header_clickable}
                    key={index}
                    onClick={() => {
                      header.ordering && sortTableBy(header.key)
                    }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <b>{header.header}</b>
                      {header.ordering && (
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '.5rem' }}>
                          {ascending && selectedHeader === header.key ? (
                            <ExpandLessIcon />
                          ) : !ascending && selectedHeader === header.key ? (
                            <ExpandMoreIcon />
                          ) : (
                            selectedHeader !== header.key && <UnfoldMoreIcon sx={{ color: '#C0C0C0' }} />
                          )}
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody sx={{ maxHeight: '30rem', overflowY: 'auto' }}>
            {loading ? (
              <TableRow>
                <TableCell colSpan={headers.length}>
                  <SkeletonLoading size={10} type="datatable" />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow>
                <TableCell size="small" colSpan={headers.length} sx={{ padding: '.5rem', textAlign: 'center' }}>
                  No se encontraron registros
                </TableCell>
              </TableRow>
            ) : (
              data.map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {row.map((item, i) => (
                    <DrawCell item={item} index={i} key={i} disableEditAction={disableEditAction} />
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaginated && (
        <TablePagination
          sx={{ borderTop: '1px solid #ddd' }}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 31, 30, 35, 40, 45, 50, 100]}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          labelRowsPerPage="Resultados por página"
          labelDisplayedRows={({ from, to, count }) => `Mostrando los resultados ${from} - ${to} de ${count} en total`}
        />
      )}
    </Paper>
  )
}

DataTable.propTypes = {
  disableEditAction: PropTypes.bool,
  headers: PropTypes.array,
  data: PropTypes.array,
  maxHeight: PropTypes.string,
  isPaginated: PropTypes.bool,
  loading: PropTypes.bool,
  total: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  sortHeaderHandler: PropTypes.func
}

export default DataTable
