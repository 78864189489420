import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AuthService from 'api/services/auth.service'
import { clearUser, setUser } from 'reduxStore/reducer/userManagementSlice'

export const useAuth = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const getAuth = async () => {
    try {
      const resp = await AuthService.getCurrentUser()
      dispatch(setUser(resp))
    } catch (error) {
      console.log(error)
    }
  }

  const handleLogout = async () => {
    try {
      await AuthService.logout()
      localStorage.clear()
      dispatch(clearUser())

      navigate('login')
    } catch (error) {
      navigate('login')
    }
  }

  return { handleLogout, getAuth }
}
