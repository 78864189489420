import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Box, ListItemIcon } from '@mui/material'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import InventoryIcon from '@mui/icons-material/Inventory'
import BusinessIcon from '@mui/icons-material/Business'
import Styles from './_SideMenu.module.scss'

const SideMenu = ({ show }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const authUser = useSelector(store => store.userManagement.user)

  return (
    <Box className={`${Styles.sidemenu} ${!show && Styles.hide}`}>
      <Box className={Styles.sidemenu__icon}>
        <img src="/img/Logo_SideMenu.png" alt="" />
      </Box>
      <hr />
      {authUser && (
        <Box className={Styles.menu}>
          <Box className={`${Styles.menu__item} ${pathname === '/' && Styles.selected}`} onClick={() => navigate('/')}>
            <ListItemIcon className={Styles.menu__item_icon}>
              <HomeOutlinedIcon color="neutral" />
            </ListItemIcon>
            Home
          </Box>
          <Box
            className={`${Styles.menu__item} ${pathname.includes('entities') && Styles.selected}`}
            onClick={() => navigate('entities')}>
            <ListItemIcon className={Styles.menu__item_icon}>
              <BusinessIcon color="neutral" />
            </ListItemIcon>
            Entidades / Empresas
          </Box>
          <Box
            className={`${Styles.menu__item} ${pathname.includes('plans') && Styles.selected}`}
            onClick={() => navigate('plans')}>
            <ListItemIcon className={Styles.menu__item_icon}>
              <InventoryIcon color="neutral" />
            </ListItemIcon>
            Planes
          </Box>
        </Box>
      )}
    </Box>
  )
}

SideMenu.propTypes = {
  show: PropTypes.bool
}

export default SideMenu
