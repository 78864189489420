export class Plan {
  id
  name
  description
  currency
  price
  stripe_plan
  slug
  created_at
  updated_at
  deleted_at

  constructor() {
    this.name = ''
    this.description = ''
    this.currency = 'MXN'
    this.price = ''
    this.slug = ''
    this.stripe_plan = ''
  }
}

export default Plan
