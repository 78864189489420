import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { FormControl, MenuItem, TextField } from '@mui/material'
import EntityService from 'api/services/entity.service'
import SideModal from 'components/SideModal/SideModal'
import Entity from 'models/Entity'
import { entityFormSchema } from 'utils/schemas/entityFormSchema'

const SideModalEditEntity = ({ open, selectedEntity, onCancel, onConfirm }) => {
  const [loading, setLoading] = useState(false)
  const [entity, setEntity] = useState(new Entity())
  const [industries, setIndustries] = useState([])

  const { errors, touched, values, handleBlur, handleSubmit, handleChange } = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    validationSchema: entityFormSchema,
    onSubmit: async vals => {
      setLoading(true)
      try {
        await EntityService.update(entity.id, vals)
        onConfirm('success')
      } catch (error) {
        console.log(error)
        onConfirm('error')
      }
      setLoading(false)
    }
  })

  const getIndustries = async () => {
    try {
      const resp = await EntityService.getIndustries()
      setIndustries(resp)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (open) {
      getIndustries()
      setEntity(selectedEntity)
    }
  }, [open])

  return (
    <SideModal
      title="Editar Entidad"
      onConfirmText="Guardar Entidad"
      open={open}
      loading={loading}
      onCancel={onCancel}
      onConfirm={handleSubmit}>
      <>
        <FormControl>
          <TextField
            name="name"
            label="Nombre"
            size="small"
            variant="outlined"
            value={values.name}
            error={Boolean(touched?.name && errors.name)}
            helperText={touched.name && errors.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            select
            name="industry_id"
            label="Industria"
            variant="outlined"
            size="small"
            value={values.industry_id}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched?.industry_id && errors.industry_id)}
            helperText={touched.industry_id && errors.industry_id}>
            {industries.map((option, index) => {
              return (
                <MenuItem key={index} value={option.id}>
                  {option.name}
                </MenuItem>
              )
            })}
          </TextField>
        </FormControl>
      </>
    </SideModal>
  )
}

SideModalEditEntity.propTypes = {
  open: PropTypes.bool,
  selectedEntity: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default SideModalEditEntity
