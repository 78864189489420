import { useEffect, useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import EntityService from 'api/services/entity.service'
import DataTable from 'components/DataTable/DataTable'
import ModalDelete from 'components/ModalDelete/ModalDelete'
import { useAlert } from 'hooks/useAlert'
import ModalManageUsers from './components/ModalManageUsers/ModalManageUsers'
import SideModalCreateEntity from './components/SideModalCreateEntity/SideModalCreateEntity'
import SideModalEditEntity from './components/SideModalEditEntity/SideModalEditEntity'
import ModalCopyCredentials from './components/ModalCopyCredentials/ModalCopyCredentials'

const Entities = () => {
  const { setAlert } = useAlert()
  const [actionType, setActionType] = useState('')
  const [deleting, setDeleting] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showSideModalEntity, setShowSideModalCreateEntity] = useState(false)
  const [showSideModalEditEntity, setShowSideModalEditEntity] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showModalManageUsers, setShowModalManageUsers] = useState(false)
  const [showModalCopyCredentials, setShowModalCopyCredentials] = useState(false)

  const [userCreated, setUserCreated] = useState()
  const [selectedEntity, setSelectedEntity] = useState()
  const [entitiesList, setEntitiesList] = useState([])

  const handleActions = (type, value) => {
    setSelectedEntity({ ...value, phone: value.phone || '' })
    setActionType(type)
    if (type === 'edit') {
      setShowSideModalEditEntity(true)
    } else if (type === 'delete') {
      setShowDeleteModal(true)
    } else if (type === 'reset_password') {
      setSelectedEntity(value)
    }
  }

  const handleManageUsers = entity => {
    setSelectedEntity(entity)
    setShowModalManageUsers(true)
  }

  const headers = [
    {
      header: 'ID',
      key: 'id',
      cellType: 'text'
    },
    {
      header: 'Nombre',
      key: 'name',
      cellType: 'text'
    },
    {
      header: 'Fecha de registro',
      key: 'created_at',
      cellType: 'date_time'
    },
    {
      header: 'Subscripción',
      key: 'subscription',
      cellType: 'text'
    },
    {
      header: 'No. de Usuarios',
      key: 'users_quantity',
      action: handleManageUsers,
      cellType: 'text_with_icon_button'
    },
    {
      header: 'Acciones',
      key: 'actions',
      cellType: 'action_edit_button',
      action: handleActions
    }
  ]

  const orderData = data => {
    const orderedData = data.map(element => {
      const row = headers.map(header => {
        let value = element[header.key]
        if (header.key === 'role') {
          value = element.role.name
        }
        if (header.key === 'users_quantity') {
          value = element.users?.length
        }
        const obj = {
          cellType: header.cellType,
          key: header.key,
          value: value,
          action: (...values) => header.action(...values, element)
        }

        return obj
      })
      return row
    })
    return orderedData
  }

  const getEntities = async () => {
    try {
      const entities = await EntityService.findAll()
      setEntitiesList(orderData(entities))
      setLoading(false)
    } catch (error) {
      setAlert(true, 'error', 'Something went wrong while retrieving Entities, try again later')
    }
  }

  const handleSaveEntity = (result, user) => {
    setShowSideModalCreateEntity(false)
    setShowSideModalEditEntity(false)
    if (result === 'success') {
      if (actionType === 'create') {
        setAlert(true, 'success', 'Entidad creada con éxito')
        setUserCreated(user)
        setShowModalCopyCredentials(true)
      } else {
        setAlert(true, 'success', 'Entidad guardada con éxito')
      }
      getEntities()
    } else {
      setAlert(true, 'error', 'Algo saliò mal al intentar guardar el Usuario, inténtalo mas tarde')
    }
  }

  const handleCreateEntity = () => {
    setActionType('create')
    setShowSideModalCreateEntity(true)
  }

  const handleDeleteEntity = async () => {
    setDeleting(true)
    try {
      await EntityService.delete(selectedEntity.id)
      setDeleting(false)
      setShowDeleteModal(false)
      setAlert(true, 'success', 'Entity deleted with success')
      getEntities()
    } catch (error) {
      setDeleting(false)
      setShowDeleteModal(false)
    }
  }

  useEffect(() => {
    getEntities()
  }, [])

  return (
    <>
      <h1>Entidades / Empresas Registradas</h1>
      <Box sx={{ margin: '2rem 0', display: 'flex', justifyContent: 'space-between' }}>
        <TextField size="small" placeholder="Search" disabled />
        <Button variant="contained" onClick={handleCreateEntity}>
          Crear Entidad
        </Button>
      </Box>
      <Box sx={{ margin: '2rem 0' }}>
        <DataTable headers={headers} data={entitiesList} loading={loading} />
      </Box>
      <SideModalCreateEntity
        open={showSideModalEntity}
        onCancel={() => setShowSideModalCreateEntity(false)}
        onConfirm={handleSaveEntity}
      />
      <SideModalEditEntity
        open={showSideModalEditEntity}
        selectedEntity={selectedEntity}
        onCancel={() => setShowSideModalEditEntity(false)}
        onConfirm={handleSaveEntity}
      />
      <ModalDelete
        element={selectedEntity}
        open={showDeleteModal}
        loading={deleting}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteEntity}
      />
      <ModalManageUsers
        entity={selectedEntity}
        users={selectedEntity?.users}
        open={showModalManageUsers}
        onCancel={() => setShowModalManageUsers(false)}
      />
      <ModalCopyCredentials
        user={userCreated}
        open={showModalCopyCredentials}
        onCancel={() => setShowModalCopyCredentials(false)}
      />
    </>
  )
}

export default Entities
