export class Entity {
  id
  name
  industry_id
  created_at
  updated_at
  deleted_at

  constructor() {
    this.name = ''
    this.industry_id = ''
  }
}

export default Entity
