import { configureStore } from '@reduxjs/toolkit'
import projectManagementSlice from './reducer/projectManagementSlice'
import userManagementSlice from './reducer/userManagementSlice'
import riskFiltersManagementSlice from './reducer/riskFiltersManagementSlice'
import alertManagementSlice from './reducer/alertManagementSlice'

const store = configureStore({
  reducer: {
    userManagement: userManagementSlice,
    projectManagement: projectManagementSlice,
    riskFiltersManagement: riskFiltersManagementSlice,
    alertManagement: alertManagementSlice
  }
})

export default store
