import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { FormControl, TextField, MenuItem } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PlanService from 'api/services/plan.service'
import { useInputValidations } from 'hooks/useInputValidations'
import { useCurrency } from 'hooks/useCurrency'
import SideModal from 'components/SideModal/SideModal'
import Plan from 'models/Plan'
import { currencies } from 'utils/constants/currencies'
import { planFormSchema } from 'utils/schemas/planFormSchema'

const SideModalPlan = ({ actionType, open, selectedPlan, onCancel, onConfirm }) => {
  const { formatStringCurrency } = useCurrency()
  const { onKeyPressValidateDecimalNumber } = useInputValidations()

  const [plan, setPlan] = useState(new Plan())
  const [loading, setLoading] = useState(false)

  const handleSavePlan = async plan => {
    try {
      actionType === 'create' ? await PlanService.store(plan) : await PlanService.update(plan.id, plan)
      setLoading(false)
      onConfirm('success')
    } catch (error) {
      console.log(error)
      setLoading(false)
      onConfirm('error')
    }
  }

  const { errors, touched, values, handleBlur, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: plan,
    enableReinitialize: true,
    validationSchema: planFormSchema,
    onSubmit: vals => {
      setLoading(true)
      handleSavePlan(vals)
    }
  })

  const handleCurrencyOnBlur = (event, value, key, isExchange = false) => {
    handleBlur(event)
    setFieldValue(key, formatStringCurrency(value.replaceAll(',', ''), false, isExchange))
  }

  useEffect(() => {
    if (open) {
      if (selectedPlan) {
        setPlan(selectedPlan)
      } else {
        setPlan(new Plan())
      }
      resetForm()
    }
  }, [open])

  return (
    <SideModal
      title={`${actionType === 'create' ? 'Nueva' : 'Editar'} Plan`}
      onConfirmText={`${actionType === 'create' ? 'Crear' : 'Guardar'} Plan`}
      open={open}
      loading={loading}
      onCancel={onCancel}
      onConfirm={handleSubmit}>
      <>
        <FormControl>
          <TextField
            name="name"
            label="Nombre"
            size="small"
            variant="outlined"
            value={values.name}
            error={Boolean(touched?.name && errors.name)}
            helperText={touched.name && errors.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            name="description"
            label="Descripción"
            multiline
            rows={4}
            size="small"
            variant="outlined"
            value={values.description}
            error={Boolean(touched?.description && errors.description)}
            helperText={touched.description && errors.description}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            name="slug"
            label="Slug"
            size="small"
            variant="outlined"
            value={values.slug}
            error={Boolean(touched?.slug && errors.slug)}
            helperText={touched.slug && errors.slug}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            name="stripe_plan"
            label="Stripe plan"
            size="small"
            variant="outlined"
            value={values.stripe_plan}
            error={Boolean(touched?.stripe_plan && errors.stripe_plan)}
            helperText={touched.stripe_plan && errors.stripe_plan}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            label={`Precio en ${values.currency}`}
            name="price"
            size="small"
            variant="outlined"
            value={values.price}
            error={Boolean(touched?.price && errors.price)}
            helperText={touched.price && errors.price}
            onBlur={e => handleCurrencyOnBlur(e, values.price, `price`)}
            onChange={handleChange}
            onKeyDown={onKeyPressValidateDecimalNumber}
            InputProps={{
              sx: { paddingRight: '0' },
              endAdornment: (
                <TextField
                  name="currency"
                  sx={{
                    minWidth: '85px',
                    width: 'auto',
                    '& fieldset': { border: '0 !important' }
                  }}
                  size="small"
                  select
                  value={values.currency}
                  SelectProps={{
                    IconComponent: props => <ExpandMoreIcon {...props} />,
                    renderValue: value => value
                  }}
                  onChange={handleChange}>
                  {currencies.map((option, index) => (
                    <MenuItem key={index} value={option.code}>
                      {option.code} - {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              ),
              startAdornment: <span>$&nbsp;</span>
            }}
          />
        </FormControl>
      </>
    </SideModal>
  )
}

SideModalPlan.propTypes = {
  actionType: PropTypes.string,
  open: PropTypes.bool,
  selectedPlan: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default SideModalPlan
