import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { FormControl, TextField, Box, MenuItem } from '@mui/material'
import EntityService from 'api/services/entity.service'
import PlanService from 'api/services/plan.service'
import SideModal from 'components/SideModal/SideModal'
import User from 'models/User'
import { useAlert } from 'hooks/useAlert'
import { entityCreationFormSchema } from 'utils/schemas/entityFormSchema'

const SideModalCreateEntity = ({ open, onCancel, onConfirm }) => {
  const { setAlert } = useAlert()
  const [loading, setLoading] = useState(false)
  const [entityForm, setEntityForm] = useState({
    entity: '',
    industry_id: '',
    ...new User(),
    role_id: 2,
    plan_id: ''
  })
  const [plans, setPlans] = useState([])
  const [industries, setIndustries] = useState([])

  const { errors, touched, values, handleBlur, handleSubmit, handleChange, resetForm, setFieldError } = useFormik({
    initialValues: entityForm,
    enableReinitialize: true,
    validationSchema: entityCreationFormSchema,
    onSubmit: async vals => {
      setLoading(true)
      try {
        await EntityService.store(vals)
        setLoading(false)
        onConfirm('success', vals)
      } catch (error) {
        console.log(error)
        setLoading(false)
        if (error.response.data.message.includes('The email has already been taken')) {
          setAlert(true, 'error', 'No es posible crear la entidad porque ya existe un usuario con el mismo email')
          setFieldError('email', 'El email ya se encuentra en uso')
        } else if (error.response.data.message.includes('The entity has already been taken')) {
          setAlert(true, 'error', 'No es posible crear la entidad porque ya existe una con el mismo nombre')
          setFieldError('entity', 'El nombre de la entidad ya se encuentra en uso')
        } else {
          onConfirm('error')
        }
      }
    }
  })

  const getPlans = async () => {
    const resp = await PlanService.findAll()
    setPlans(resp)
  }

  const getIndustries = async () => {
    try {
      const resp = await EntityService.getIndustries()
      setIndustries(resp)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (open) {
      getPlans()
      getIndustries()
      setEntityForm({
        entity: '',
        industry_id: '',
        ...new User(),
        role_id: 2,
        plan_id: ''
      })
      resetForm()
    }
  }, [open])

  return (
    <SideModal
      title="Nueva Entidad"
      onConfirmText="Crear Entidad"
      open={open}
      loading={loading}
      onCancel={onCancel}
      onConfirm={handleSubmit}>
      <>
        <FormControl>
          <TextField
            required
            name="entity"
            label="Nombre"
            size="small"
            variant="outlined"
            value={values.entity}
            error={Boolean(touched?.entity && errors.entity)}
            helperText={touched.entity && errors.entity}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            select
            required
            name="industry_id"
            label="Industria"
            variant="outlined"
            size="small"
            value={values.industry_id}
            onBlur={handleBlur}
            onChange={handleChange}
            error={Boolean(touched?.industry_id && errors.industry_id)}
            helperText={touched.industry_id && errors.industry_id}>
            {industries.map((option, index) => {
              return (
                <MenuItem key={index} value={option.id}>
                  {option.name}
                </MenuItem>
              )
            })}
          </TextField>
        </FormControl>
        <FormControl>
          <Box sx={{ marginBottom: '1rem', fontSize: '.75rem', color: '#888' }}>
            Con la siguiente información personal se creará un usuario "Propietario", todos los cargos del plan que se
            elija se asociarán a éste usuario
          </Box>
          <TextField
            required
            name="name"
            label="Nombre del usuario propietario"
            size="small"
            variant="outlined"
            value={values.name}
            error={touched?.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            required
            name="last_name"
            label="Apellidos usuario propietario"
            size="small"
            variant="outlined"
            value={values.last_name}
            error={touched?.last_name && Boolean(errors.last_name)}
            helperText={touched.last_name && errors.last_name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            name="phone"
            label="Teléfono fijo"
            size="small"
            variant="outlined"
            value={values.phone}
            error={touched?.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            name="cellphone"
            label="Celular"
            size="small"
            variant="outlined"
            value={values.cellphone}
            error={touched?.cellphone && Boolean(errors.cellphone)}
            helperText={touched.cellphone && errors.cellphone}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            required
            name="email"
            label="Email usuario propietario"
            placeholder="Ingresa tu email"
            size="small"
            variant="outlined"
            value={values.email}
            error={touched?.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            required
            name="password"
            label="Contraseña"
            size="small"
            variant="outlined"
            type="password"
            value={values.password}
            error={touched?.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            required
            name="password_confirmation"
            label="Confirmar contraseña"
            size="small"
            variant="outlined"
            type="password"
            value={values.password_confirmation}
            error={touched?.password_confirmation && Boolean(errors.password_confirmation)}
            helperText={touched.password_confirmation && errors.password_confirmation}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <TextField
            select
            name="plan_id"
            size="small"
            variant="outlined"
            label="Plan de subscripción"
            value={values.plan_id}
            onBlur={handleBlur}
            onChange={handleChange}>
            {plans.map((plan, index) => (
              <MenuItem key={index} value={plan.id}>
                {plan.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </>
    </SideModal>
  )
}

SideModalCreateEntity.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default SideModalCreateEntity
