import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute'
import React from 'react'
import Entities from 'views/Entities/Entities'
import Home from 'views/Home/Home'
import Layout from 'views/Layout/Layout'
import Login from 'views/Login/Login'
import NotFound from 'views/NotFound/NotFound'
import SubscriptionPlans from 'views/Plans/Plans'
import Profile from 'views/Profile/Profile'

const routes = [
  {
    path: '',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'entities',
        element: <Entities />
      },
      {
        path: 'plans',
        element: <SubscriptionPlans />
      },
      {
        path: 'profile',
        element: <Profile />
      }
    ]
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: '*',
    element: <NotFound />
  }
]

export default routes
