import { Alert, Snackbar } from '@mui/material'
import PropTypes from 'prop-types'

const AlertPopup = ({ open, handleClose, message = 'Alert', severity }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={3500}
      onClose={handleClose}
      message="I love snacks"
      key={message}>
      <Alert severity={severity || 'success'}>{message}</Alert>
    </Snackbar>
  )
}

AlertPopup.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string,
  severity: PropTypes.string
}

export default AlertPopup
