import axios from '../axios'

class EntityService {
  static async findAll() {
    const resp = await axios.get('/entities')
    return resp.data
  }

  static async store(params) {
    const resp = await axios.post(`/entities`, { ...params })
    return resp.data
  }

  static async update(id, params) {
    const resp = await axios.put(`/entities/${id}`, { ...params })
    return resp.data
  }

  static async getIndustries() {
    const resp = await axios.get(`/industries`)
    return resp.data
  }
}

export default EntityService
