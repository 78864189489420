import axios from '../axios'

class PlanService {
  static async findAll() {
    const resp = await axios.get('/plans')
    return resp.data
  }

  static async store(params) {
    const resp = await axios.put(`/plans`, { ...params })
    return resp.data
  }

  static async update(id, params) {
    const resp = await axios.put(`/plans/${id}`, { ...params })
    return resp.data
  }
}

export default PlanService
